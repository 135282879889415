import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Ana Sayfa | Kartepe ATV Safari - Maceraya Atılın
			</title>
			<meta name={"description"} content={"Birinci sınıf ATV ve UTV kiralamalarımızla off-road heyecanını yaşayın"} />
			<meta property={"og:title"} content={"Ana Sayfa | Kartepe ATV Safari - Maceraya Atılın"} />
			<meta property={"og:description"} content={"Birinci sınıf ATV ve UTV kiralamalarımızla off-road heyecanını yaşayın"} />
			<meta property={"og:image"} content={"https://novaverolip.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://novaverolip.com/images/108754334.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://novaverolip.com/images/108754334.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://novaverolip.com/images/108754334.png"} />
			<meta name={"msapplication-TileImage"} content={"https://novaverolip.com/images/108754334.png"} />
			<meta name={"msapplication-TileColor"} content={"https://novaverolip.com/images/108754334.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="80px 0 90px 0"
			md-padding="80px 0 50px 0"
			overflow-x="hidden"
			overflow-y="hidden"
			quarkly-title="Hero-14"
		>
			<Override slot="SectionContent" width="100%" min-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				md-flex-direction="column"
				md-justify-content="flex-end"
				md-align-items="flex-end"
				sm-z-index="2"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="60%"
					padding="80px 80px 80px 80px"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					sm-padding="50px 25px 50px 25px"
					sm-position="relative"
					sm-z-index="2"
				>
					<Text margin="0px 0px 30px 0px" font="normal 600 62px/1.2 --fontFamily-serifGaramond" sm-font="normal 600 42px/1.2 --fontFamily-serifGaramond">
					Kartepe ATV Safari - 
						<br />
						Maceraya Atılın
					</Text>
					<Text margin="0px 0px 60px 0px" font="--base">
					Kartepe ATV Safari'de en üst düzey off-road macerasını sunuyoruz. Birinci sınıf ATV ve UTV'lerden oluşan filomuz, vahşi ve engebeli arazileri keşfetmek isteyen herkes için mükemmeldir. İster deneyimli bir sürücü ister off-road'da yeni olun, sizin için doğru araca sahibiz. Yıllarca hatırlayacağınız heyecan verici bir yolculuğa çıkmaya hazırlanın.
					</Text>
					<Button
						background="--color-primary"
						border-radius="4px"
						padding="16px 28px 16px 28px"
						font="--base"
						transition="background-color 0.2s ease 0s"
						border-width="2px"
						border-style="solid"
						border-color="--color-primary"
						hover-color="--darkL1"
						hover-background="rgba(237, 97, 26, 0.03)"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
					>
						Bize Ulaşın
					</Button>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					width="40%"
					justify-content="flex-end"
					align-items="center"
					md-margin="-120px 0px 0px 0px"
					md-width="60%"
					sm-position="relative"
					sm-z-index="1"
				>
					<Image
						src="https://novaverolip.com/images/1.jpg"
						display="block"
						margin="0px -100px 0px 0px"
						height="100%"
						md-margin="0px 0 0px 0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					lg-height="auto"
				>
					<Image
						height="589px"
						box-shadow="0 10px 20px -10px rgba(43, 45, 84, 0.15),0 0px 20px 0 rgba(43, 45, 84, 0.05)"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						src="https://novaverolip.com/images/2.jpg"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text
					as="h2"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Neden Kartepe ATV Safari'yi Seçmelisiniz?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-max-width="720px"
				>
					İster tek başınıza bir sürüş, ister bir aile gezisi veya bir grup keşif gezisi planlıyor olun, ihtiyaçlarınızı karşılamak için çeşitli kiralama seçenekleri sunuyoruz. Her kiralama, maceranızın sorunsuz geçmesini sağlamak için kapsamlı bir brifing ve güvenlik talimatları içerir. Rehberli bir deneyimi tercih edenler için deneyimli rehberler eşliğinde turlar da düzenliyoruz.
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--greyD2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Yüksek Kaliteli Araçlar: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Filomuzda en son model ATV ve UTV'ler bulunmakta olup, en yüksek performans ve güvenilirliği garanti etmektedir.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text
							padding="0"
							margin="0"
							color="--darkL2"
							font="18px/27px --fontFamily-sans"
							lg-max-width="720px"
						>
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Güvenlik Önceliği: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Ayrıntılı brifingler, güvenlik donanımları ve bakımlı ekipmanlarla güvenliğiniz bizim için en önemli konudur.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" lg-max-width="720px">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Olağanüstü Müşteri Hizmetleri: {" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Güler yüzlü ve bilgili personelimiz, rezervasyondan aracınızı iade etmeye kadar size yardımcı olmaya kendini adamıştır.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-4">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				width="65%"
				justify-content="center"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				lg-width="100%"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						top={0}
						left={0}
						position="absolute"
						display="block"
						width="100%"
						right={0}
						src="https://novaverolip.com/images/3.jpg"
						object-fit="cover"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					padding="0px 0px 65% 0px"
					width="100%"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						min-height="100%"
						src="https://novaverolip.com/images/4.jpg"
					/>
				</Box>
				<Text
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					md-text-align="left"
				>
					Kartepe ATV Safari, heyecan verici ve güvenli bir off-road deneyimi sunmayı taahhüt eder. Misyonumuz, her seviyeden macera arayanlara güvenilir ve heyecan verici ATV ve UTV kiralama hizmeti sunmaktır. Tutkulu ekibimiz, her aracın mükemmel durumda olmasını ve her türlü araziyi fethetmeye hazır olmasını sağlar. Olağanüstü müşteri hizmetleri sunmaktan ve maceranızın hem eğlenceli hem de güvenli olmasını sağlamaktan gurur duyuyoruz.
				</Text>
				<Box
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					padding="0px 0px 65% 0px"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						bottom="0px"
						min-height="100%"
						src="https://novaverolip.com/images/5.jpg"
						object-fit="cover"
						position="absolute"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Açık havayı keşfetmeye hazır mısınız? ATV veya UTV'nizi şimdi rezerve edin ve maceranıza Kartepe ATV Safari ile başlayın. Heyecan dolu bir deneyim, muhteşem manzaralar ve unutulmaz anılar vaat ediyoruz. Gecikmeyin - off-road maceranız sizi bekliyor!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});